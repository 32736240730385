import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from ;
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {store, persistor} from "./redux/store";
// import Loading from "./shared/Loading";
import { PersistGate } from 'redux-persist/integration/react'


const App = React.lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter>
          <Suspense fallback={<>Loading....</>}>
            <App />
          </Suspense>
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();