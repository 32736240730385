import { generalstate } from '../gvariables'

const GeneralReducer = (state = generalstate, action) => {
	// eslint-disable-next-line default-case
	console.log({action})
	switch (action.type) {
		case 'LOGIN_USER':
			console.log("user login", action.user)
			state = {
				...state,
				user: action.user.user,
				token: action.user.jwt,
				wishlist: action.user.user.wishlist,
			}
			localStorage.setItem('token', `${action.user.jwt}`)
			break;
		case 'LOGOUT':
			console.log(action.user)
			state = {
				...state,
				user: {},
				token: undefined,
				wishlist: [],
			}
			localStorage.clear()
			break;
		case 'UPDATE_WISHLIST':
			state = {
				...state,
				cart: action.wishlist,
			}
			break;
		default: break;
	}

	return state
}

export default GeneralReducer